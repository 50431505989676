import React, { useCallback, useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";
import OtpInput from "react18-input-otp";
import { api } from "../../lib/axios";
import MKSnackbar from "../MKSnackbar";

function DocumentGenerator(params) {
  const { appointmentInfo, closeModal } = params;
  const authenticatedLocalUser =
    JSON.parse(localStorage.getItem("user")) ?? false;

  const [loading, setLoading] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    severity: "",
  });

  const [documentText, setDocumentText] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [documentType, setDocumentType] = useState("PRESCRIPTION");

  const TypeDocumentEnum = {
    PRESCRIPTION: "Prescrição",
    REPORT: "Relatório",
    CERTIFICATE: "Atestado",
    REFERRAL: "Encaminhamento",
    EXAM_REQUEST: "Solicitação de Exame",
  };

  const modules = useCallback(
    () => ({
      toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
          { align: [] },
        ],
      ],
    }),
    [],
  );

  const formats = useCallback(
    () => [
      "header",
      "height",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "color",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "size",
    ],
    [],
  );

  const submitDocument = useCallback(async () => {
    if (!authenticatedLocalUser) {
      setAlertComponent({
        message: "Usuário não autenticado. Refaça o login.",
        severity: "error",
      });
      return;
    }
    if (!appointmentInfo) {
      setAlertComponent({
        message: "Informações de atendimento não encontrado.",
        severity: "error",
      });
      return;
    }
    if (otpCode === "" || otpCode.length < 6) {
      setAlertComponent({
        message: "Informe o código de validação",
        severity: "error",
      });
      return;
    }
    if (documentText === "") {
      setAlertComponent({
        message: "Informe o texto do documento",
        severity: "error",
      });
      return;
    }
    setAlertComponent({
      message: "Gerando documento... Aguarde! ",
      severity: "info",
    });
    setLoading(true);
    try {
      const dataRequest = {
        temporaryCode: otpCode,
        text: documentText,
        type: documentType,
      };
      const response = await api.post(
        `/schedules/${appointmentInfo.id}/prescription`,
        dataRequest,
      );
      setOtpCode("");

      if (response.status === 401) {
        setAlertComponent({
          message: "Acesso não autorizado. Refaça o login",
          severity: "error",
        });
        return;
      }
      if (response.status >= 400) {
        setAlertComponent({
          message: "Erro ao gerar documento",
          severity: "error",
        });
        return;
      }

      setDocumentText("");
      closeModal();

      setAlertComponent({
        message: "Documento gerado com sucesso",
        severity: "success",
      });
    } catch (error) {
      setAlertComponent({
        message: "Erro ao gerar documento",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    authenticatedLocalUser,
    appointmentInfo,
    documentText,
    otpCode,
    documentType,
    setLoading,
    closeModal,
  ]);

  const handleDocumentTextChange = useCallback((content) => {
    setDocumentText(content);
  }, []);

  const handleDocumentTypeChange = useCallback((e) => {
    setDocumentType(e.target.value);
  }, []);

  const handleOtpCodeChange = useCallback((value) => {
    setOtpCode(value);
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          severity: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Processando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>

      <MKSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        icon="notifications"
        title="Notificação"
        content={alertComponent.message}
        open={alertComponent.message !== ""}
        close={() => setAlertComponent({ message: "", severity: "" })}
        color={alertComponent.severity}
        dateTime=""
      />

      <Box sx={{ display: "flex", flexDirection: "column", padding: "10px" }}>
        <ReactQuill
          id="documentInput"
          theme="snow"
          modules={modules()}
          formats={formats()}
          placeholder="Insira o texto aqui..."
          value={documentText}
          onChange={handleDocumentTextChange}
          style={{ height: "300px", marginBottom: "50px" }}
        />
        <Box sx={{ padding: "10px" }}>
          <FormControl>
            <FormLabel id="documentTypeSelection">Tipo do documento:</FormLabel>
            <RadioGroup
              row
              aria-labelledby="documentTypeSelection"
              name="position"
              defaultValue="top"
              value={documentType}
              onChange={handleDocumentTypeChange}
            >
              {Object.keys(TypeDocumentEnum).map((key) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={TypeDocumentEnum[key]}
                  checked={documentType === key}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            border: "1px solid #ccc",
          }}
        >
          <Box sx={{ padding: "10px" }}>
            <InputLabel htmlFor="otpCode">
              Código de Validação BirdID
            </InputLabel>
          </Box>
          <OtpInput
            id="otpCode"
            numInputs={6}
            value={otpCode}
            onChange={handleOtpCodeChange}
            inputStyle={{
              width: "100px",
              height: "80px",
              margin: "5px",
              fontSize: "20px",
              borderRadius: "5px",
              border: "1px solid #ccc",
            }}
          />
          <Box sx={{ padding: "10px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={submitDocument}
            >
              Gerar Documento
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default DocumentGenerator;
