/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import routes from "routes";
import { Context } from "Context/AuthContext";
import * as jose from "jose";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import MedicalTimeLine from "components/MedicalTimeLine";

import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKButton from "components/MKButton";

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import LogoMPO from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function ProfessionalAppointmentDetails() {
  const { handleLogout } = useContext(Context);
  const { id } = useParams();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });

  const [timeLeft, setTimeLeft] = useState(null);
  const [timeFinished, setTimeFinished] = useState(0);
  const [loading, setLoading] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [userMedicalRecords, setUserMedicalRecords] = useState(null);

  const TypeDocumentParse = {
    prescription: "RECEITA",
    report: "RELATÓRIO",
    certificate: "ATESTADO ",
    referral: "ENCAMINHAMENTO",
    exam: "SOLICITAÇÃO EXAME",
    medical: "PRONTUÁRIO",
  };

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  if (!authenticatedUser) {
    window.location.replace("/login");
  }

  const routeIndex = authenticatedUser.type ?? "public";

  const getAppointmentDetails = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/schedules/${id}`);
      setAppointmentDetails(response.data);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const downloadFile = async (fileId) => {
    const host = window.location.origin;
    window.open(`${host}/download/${fileId}`, "_blank");
  };

  const getUserMedicalRecords = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/medicalRecords`, {
        params: {
          user_id: appointmentDetails.user.id,
        },
      });
      const medicalRecordsFiltered = response.data.filter(
        (record) => record.report !== "",
      );
      setUserMedicalRecords(medicalRecordsFiltered);
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const calculateTimeLeft = () => {
    const difference = dayjs(appointmentDetails.date_start)
      .tz("America/Sao_Paulo")
      .add(3, "hours")
      .diff(dayjs().tz("America/Sao_Paulo"));
    setTimeFinished(difference);
    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return false;
  };

  const finalizeService = async () => {
    const timeLimitToFinished = dayjs(appointmentDetails.date_start)
      .add(3, "hours")
      .add(15, "minutes");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    if (timeNow.isBefore(timeLimitToFinished)) {
      setAlertComponent({
        message: "Atendimento não pode ser finalizado com menos 15 minutos.",
        type: "error",
      });
      return;
    }
    setLoading(true);
    try {
      await api.put(`/schedules/${appointmentDetails.id}/finalize`);
      setAlertComponent({
        message: "Atendimento finalizado com sucesso!",
        type: "success",
      });
      window.location.reload();
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (appointmentDetails) {
      setTimeout(() => setTimeLeft(calculateTimeLeft()), 1000);
    }
  }, [timeLeft, appointmentDetails]);

  useEffect(() => {
    async function getInfos() {
      if (appointmentDetails && !userMedicalRecords) {
        await getUserMedicalRecords();
      }
    }
    getInfos();
  }, [appointmentDetails]);

  useEffect(() => {
    const decodedToken = jose.decodeJwt(localStorage.getItem("token"));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (
      authenticatedUser.type !== "professional" ||
      decodedToken.exp <= currentTimestamp
    ) {
      handleLogout();
      window.location.replace("/login");
    }

    async function getInfos() {
      if (!appointmentDetails) {
        await getAppointmentDetails();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={20}
          width="100%"
          height="100vh"
        >
          <Grid container alignItems="top">
            <Grid item xs={12} lg={12} py={3}>
              <Card>
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={3}
                  mb={1}
                  textAlign="center"
                >
                  <MKTypography
                    variant="h3"
                    fontWeight="medium"
                    color="white"
                    mt={1}
                  >
                    Informações do Agendamento
                  </MKTypography>
                </MKBox>
                {!authenticatedUser && (
                  <Grid container textAlign="center">
                    <Typography variant="h2" textAlign="center">
                      Acesso Negado.
                    </Typography>
                  </Grid>
                )}
                {authenticatedUser && appointmentDetails && (
                  <Grid container textAlign="center">
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      py={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MKBox mx={2} mb={1} height="auto">
                        <MKTypography variant="h4" textAlign="center" pb={3}>
                          Detalhes do Paciente
                        </MKTypography>
                        <MKTypography variant="h3" textAlign="center">
                          {appointmentDetails.user.name}
                        </MKTypography>
                        <MKTypography variant="h5" textAlign="center">
                          Data do atendimento:
                        </MKTypography>
                        <MKTypography variant="h5" textAlign="center">
                          {dayjs(appointmentDetails.date_start)
                            .tz("America/Sao_Paulo")
                            .add(3, "hours")
                            .format("DD/MM/YYYY [às] HH:mm")}{" "}
                          (Horário de Brasília)
                        </MKTypography>
                      </MKBox>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      py={2}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <MKBox mx={2} mb={1} height="auto">
                        {appointmentDetails.status === "AVALIAÇÃO" && (
                          <MKBox
                            component="section"
                            p={{ xs: 2, md: 5 }}
                            bgColor="#f0f0f0"
                            borderRadius="10px"
                          >
                            <MKTypography variant="body1" textAlign="justify">
                              Atendimento finalizado.
                            </MKTypography>
                          </MKBox>
                        )}
                        {appointmentDetails.status === "AGENDADO" && (
                          <Grid>
                            {timeLeft !== null && timeFinished > 0 && (
                              <MKBox
                                component="section"
                                py={{ xs: 2, md: 5 }}
                                bgColor="#f0f0f0"
                                borderRadius="10px"
                              >
                                <MKBox>
                                  <MKTypography
                                    variant="h5"
                                    px={3}
                                    textAlign="center"
                                  >
                                    O atendimento começará em:
                                  </MKTypography>
                                  <MKTypography
                                    variant="h4"
                                    px={3}
                                    textAlign="center"
                                    py={2}
                                  >
                                    <Grid
                                      container
                                      display="flex"
                                      justifyContent="space-around"
                                    >
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.days).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          DIAS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.hours).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          HORAS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.minutes).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          MINUTOS
                                        </MKTypography>
                                      </MKBox>
                                      <MKBox>
                                        <MKTypography variant="h3">
                                          {String(timeLeft.seconds).padStart(
                                            2,
                                            "0",
                                          )}
                                        </MKTypography>
                                        <MKTypography variant="body2">
                                          SEGUNDOS
                                        </MKTypography>
                                      </MKBox>
                                    </Grid>
                                  </MKTypography>
                                  <MKTypography
                                    variant="body2"
                                    px={3}
                                    textAlign="center"
                                  >
                                    Quando chegar a hora do seu atendimento,
                                    esta mensagem será substituída por um botão
                                    para você se encontrar com o paciente.
                                  </MKTypography>
                                </MKBox>
                              </MKBox>
                            )}
                            {timeLeft !== null && timeFinished <= 0 && (
                              <MKBox m={5}>
                                <Typography
                                  variant="body2"
                                  textAlign="justify"
                                  pb={3}
                                >
                                  Clique no botão para se encontrar com o
                                  paciente.
                                </Typography>
                                <MKButton
                                  variant="gradient"
                                  color="success"
                                  // component={Link}
                                  // to={`/meeting-room/${appointmentDetails.id}`}
                                  onClick={() =>
                                    window.open(
                                      `${window.location.origin}/meeting-room/${appointmentDetails.id}`,
                                      "_blank",
                                      "noopener,noreferrer",
                                    )
                                  }
                                  size="large"
                                  fullWidth
                                  sx={{ marginY: 1 }}
                                >
                                  ACESSAR SALA DE ATENDIMENTO
                                </MKButton>
                                <MKButton
                                  variant="gradient"
                                  color="error"
                                  onClick={() => finalizeService()}
                                  size="large"
                                  fullWidth
                                  sx={{ marginY: 1 }}
                                >
                                  FINALIZAR ATENDIMENTO
                                </MKButton>
                                {/* <Typography
                                  variant="body2"
                                  textAlign="justify"
                                  pb={3}
                                >
                                  Acesse a área de anotações e a utilize como
                                  prontuário eletrônico para esse atendimento
                                </Typography>
                                <MKButton
                                  variant="gradient"
                                  color="error"
                                  component={Link}
                                  to={`/medicalrecords/${appointmentDetails.id}`}
                                  size="large"
                                  fullWidth
                                >
                                  PRONTUÁRIO ELETRÔNICO PARA ESSE ATENDIMENTO
                                </MKButton> */}
                              </MKBox>
                            )}
                          </Grid>
                        )}
                        {appointmentDetails.status === "PROCESSANDO" && (
                          <MKBox>
                            <Typography variant="h4">
                              PENDENTE DE PAGAMENTO
                            </Typography>
                          </MKBox>
                        )}
                        {appointmentDetails.status === "FINALIZADO" && (
                          <MKBox>
                            <Typography variant="h4">
                              ATENDIMENTO FINALIZADO
                            </Typography>
                          </MKBox>
                        )}
                      </MKBox>
                    </Grid>
                  </Grid>
                )}
              </Card>
            </Grid>
            {appointmentDetails?.attachments &&
              appointmentDetails.attachments.length > 0 && (
                <Grid item xs={12} lg={12} py={3}>
                  <Card>
                    <MKBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={1}
                      mb={1}
                      textAlign="center"
                    >
                      <MKTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={1}
                      >
                        Arquivos do Paciente
                      </MKTypography>
                    </MKBox>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        flexWrap: "wrap",
                        border: "1px solid #f0f0f0",
                        borderRadius: "10px",
                        padding: "10px",
                        margin: "20px",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {appointmentDetails.attachments.map((file) => (
                        <Tooltip
                          title="Baixar arquivo"
                          placement="top-start"
                          arrow
                        >
                          <Box
                            key={file.id}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignContent: "center",
                              alignItems: "center",
                              border: "1px solid #f0f0f0",
                              borderRadius: "10px",
                              backgroundColor: "#f0f0f0",
                              padding: "10px",
                              width: "200px",
                              height: "100px",
                              margin: "10px",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => downloadFile(file.uuid)}
                          >
                            <Box sx={{ height: "50px" }}>
                              <DownloadForOfflineIcon
                                fontSize="large"
                                height="50px"
                              />
                            </Box>
                            <Typography variant="caption" lineHeight={0.8}>
                              {file?.description !== "" &&
                              Object.keys(TypeDocumentParse).some((key) =>
                                file.description.startsWith(key),
                              )
                                ? TypeDocumentParse[
                                    file.description.split("_")[0]
                                  ]
                                : file.description}
                            </Typography>
                          </Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </Card>
                </Grid>
              )}
          </Grid>
        </MKBox>
      </Container>
      {appointmentDetails?.user?.id && (
        <MedicalTimeLine
          patientId={appointmentDetails.user.id}
          userName={appointmentDetails.user.name}
        />
      )}
    </>
  );
}

export default ProfessionalAppointmentDetails;
