import React, { useEffect, useState, useCallback } from "react";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import { api } from "../../lib/axios";
import MKSnackbar from "../MKSnackbar";

function EletronicMedicalRecord(params) {
  const { appointmentInfos } = params;
  const authenticatedLocalUser =
    JSON.parse(localStorage.getItem("user")) ?? false;

  const windowHeight = window?.innerHeight || null;
  const windowWidth = window?.innerWidth || null;

  const [loading, setLoading] = useState(false);
  const [alertInfos, setAlertInfos] = useState({
    severity: "",
    message: "",
  });

  const [toggleWindow, setToggleWindow] = useState(false);
  const [documentText, setDocumentText] = useState("");

  const handleDrawer = useCallback(() => {
    setToggleWindow((prevState) => !prevState);
  }, []);

  const submitMedicalRecord = useCallback(async () => {
    if (!authenticatedLocalUser) {
      setAlertInfos({
        message: "Seu acesso expirou ou é inválido, faça login novamente",
        severity: "error",
      });
      return;
    }
    if (documentText === "") {
      setAlertInfos({
        message: "Não é possível salvar um prontuário vazio",
        severity: "error",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(`/medicalRecords`, {
        schedule_id: appointmentInfos.id,
        report: documentText,
      });

      if (response.status >= 400) {
        setAlertInfos({
          message: "Erro ao atualizar prontuário",
          severity: "error",
        });
        return;
      }

      setAlertInfos({
        message: "Prontuário salvo com sucesso",
        severity: "success",
      });
    } catch (error) {
      setAlertInfos({
        message: "Erro ao salvar prontuário",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    appointmentInfos.id,
    documentText,
    authenticatedLocalUser,
    setLoading,
    setAlertInfos,
  ]);

  useEffect(() => {
    if (documentText !== "") {
      const sessionAttendanceInfosString =
        sessionStorage?.getItem("attendanceInfos");
      const localInfos = sessionAttendanceInfosString
        ? JSON.parse(sessionAttendanceInfosString)
        : {};
      localInfos.documentText = documentText;
      sessionStorage.setItem("attendanceInfos", JSON.stringify(localInfos));
    }
  }, [documentText]);

  useEffect(() => {
    const sessionString = sessionStorage.getItem("attendanceInfos");
    if (sessionString) {
      const localInfos = JSON.parse(sessionString);
      setDocumentText(localInfos.documentText);
    } else {
      setDocumentText(appointmentInfos?.report || "");
    }
  }, [appointmentInfos?.report]);

  useEffect(() => {
    if (alertInfos.message !== "") {
      setTimeout(() => {
        setAlertInfos({
          message: "",
          severity: "",
        });
      }, 10000);
    }
  }, [alertInfos]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MKSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        icon="notifications"
        title="Notificação"
        content={alertInfos.message}
        open={alertInfos.message !== ""}
        close={() => setAlertInfos({ message: "", severity: "" })}
        color={alertInfos.severity}
        dateTime=""
      />

      <Box
        sx={{
          position: "fixed",
          right: `${toggleWindow ? windowWidth / 3 : 0}px`,
          backgroundColor: "#FF785B70",
          width: "50px",
          bottom: `${Math.round(windowHeight / 2) - 125}px`,
          borderRadius: "10px 0px 0px 10px",
          zIndex: 20000,
          cursor: "pointer",
        }}
        onClick={handleDrawer}
      >
        <Box sx={{ position: "relative", height: "200px" }}>
          <Typography
            sx={{
              transform: "rotate(-90deg)",
              position: "absolute",
              transformOrigin: "center left",
              width: "250px",
              top: 180,
              left: 20,
              fontWeight: "bold",
              fontSize: "14px",
              zIndex: 30000,
            }}
            variant="caption"
          >
            Prontuário Eletrônico
          </Typography>
        </Box>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Drawer anchor="right" open={toggleWindow} onClose={handleDrawer}>
        <Box
          style={{
            width: `${windowWidth / 3}px`,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              paddingTop: "80px",
              paddingBottom: "10px",
            }}
          >
            Prontuário Eletrônico
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button variant="contained" onClick={submitMedicalRecord}>
              Salvar Prontuário
            </Button>
          </Box>
          <ReactQuill
            id="medicalRecordInput"
            theme="snow"
            modules={{
              toolbar: [
                [{ size: ["small", false, "large", "huge"] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
              ],
            }}
            formats={[
              "header",
              "height",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "color",
              "bullet",
              "indent",
              "link",
              "image",
              "align",
              "size",
            ]}
            placeholder="Insira o texto aqui..."
            value={documentText}
            onChange={(content) => setDocumentText(content)}
            style={{ height: `${windowHeight - 300}px`, padding: "10px" }}
          />
        </Box>
      </Drawer>
    </>
  );
}

export default EletronicMedicalRecord;
