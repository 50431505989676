import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
} from "@mui/material";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import EletronicMedicalRecord from "components/EletronicMedicalRecord";
import MedicalTimeLine from "components/MedicalTimeLine";

import * as jose from "jose";
import CloseIcon from "@mui/icons-material/Close";
import MeetingRoom from "../../components/MeetingRoom";
import DocumentSignature from "../../components/DocumentSignature";

import phoneoff from "../../assets/phoneoff.svg";

import { api } from "../../lib/axios";
import DocumentGenerator from "../../components/DocumentGenerator";
import { Context } from "../../Context/AuthContext";
import MKSnackbar from "../../components/MKSnackbar";

dayjs.extend(utc);
dayjs.extend(timezone);

function MeetingRoomPage() {
  const { id } = useParams();
  const { handleLogout } = useContext(Context);
  const authenticatedUser = JSON.parse(localStorage.getItem("user"));

  const isDesktop = useMediaQuery("(min-width:768px)");
  const windowWidth = window?.innerWidth || null;

  const intervalId = useRef;
  const roomIntervalId = useRef;

  const [loading, setLoading] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    severity: "",
  });

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timeRoomLeft, setTimeRoomLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [meetingAuthorized, setMeetingAuthorized] = useState(false);
  const [meetingFinished, setMeetingFinished] = useState(false);

  const [signatureModal, setSignatureModal] = useState(false);
  const [documentGeneratorModal, setDocumentGeneratorModal] = useState(false);
  const [notFoundModal, setNotFoundModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [getUserNameModal, setGetUserNameModal] = useState(false);

  const [userNameInput, setUserNameInput] = useState("");

  const [localUserName, setLocalUserName] = useState(() => {
    const storedUserName = sessionStorage.getItem("localUserName");
    return storedUserName ? JSON.parse(storedUserName) : "";
  });
  const [termsAccepted, setTermsAccepted] = useState(() => {
    const storedTermsAccepted = sessionStorage.getItem("termsAccepted");
    return storedTermsAccepted === "true";
  });

  const [appointmentDetails, setAppointmentDetails] = useState(null);

  const closeNotFoundModal = useCallback(() => {
    setNotFoundModal(false);
    window.close();
  }, [window]);

  const finishAndRollback = useCallback(async () => {
    sessionStorage.removeItem("attendanceInfos");
    if (window.history.length > 1) {
      window.history.go(-1);
    } else {
      window.close();
    }
  }, [window]);

  const closeSignatureModal = async () => {
    setSignatureModal(false);
    await finishAndRollback();
  };

  const handleGetUserNameModal = useCallback(() => {
    if (localUserName === "" && userNameInput === "") {
      setAlertComponent({
        message: "Informe seu nome para entrar na sala de atendimento",
        severity: "error",
      });
      setGetUserNameModal(true);
      return;
    }
    setLocalUserName(userNameInput);
    setGetUserNameModal(false);
  }, [localUserName, userNameInput]);

  const testCameraPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const testMicrophonePermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const acceptTermsModal = useCallback(async () => {
    const statusCamera = await testCameraPermission();
    const statusMic = await testMicrophonePermission();
    if (statusCamera && statusMic && !termsAccepted) {
      setAcceptModal(false);
      setTermsAccepted(true);
    }
  }, [testCameraPermission, testMicrophonePermission, termsAccepted]);

  const calculateTimeLeft = () => {
    if (!appointmentDetails) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const meetDateTime = dayjs(appointmentDetails.date_start).add(3, "hours");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    const difference = meetDateTime.diff(timeNow);
    if (difference < 0 && !meetingAuthorized) {
      setMeetingAuthorized(true);
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const calculateTimeRoomLeft = () => {
    if (!appointmentDetails) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const meetLimit = dayjs(appointmentDetails.date_end).add(3, "hours");
    const timeNow = dayjs().tz("America/Sao_Paulo");
    const difference = meetLimit.diff(timeNow);
    if (difference < 0 && meetingAuthorized && !meetingFinished) {
      setMeetingAuthorized(false);
      setMeetingFinished(true);
    }
    if (difference < 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    return {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const getProfessionalInfos = async (professionalId) => {
    try {
      const { data } = await api.get(`/professionals/${professionalId}`);
      return data;
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca do profissional",
        severity: "error",
      });
    }
    return {};
  };

  const finalizeAppointment = useCallback(async () => {
    if (!appointmentDetails) {
      setAlertComponent({
        message: "Atendimento não encontrado.",
        severity: "error",
      });
      return;
    }
    setLoading(true);
    try {
      if (
        authenticatedUser?.type === "professional" &&
        authenticatedUser?.professional?.id ===
          appointmentDetails.professional.id
      ) {
        const timeLimitToFinished = dayjs(appointmentDetails.date_start)
          .add(3, "hours")
          .add(10, "minutes");
        const timeNow = dayjs().tz("America/Sao_Paulo");
        if (timeNow.isBefore(timeLimitToFinished)) {
          setAlertComponent({
            message:
              "Atendimento não pode ser finalizado com menos 10 minutos.",
            severity: "error",
          });
          return;
        }

        await api.put(`/schedules/${id}/finalize`);
        setAlertComponent({
          message: "Atendimento finalizado com sucesso!",
          severity: "success",
        });

        sessionStorage.clear();

        const { data } = await api.get(`/schedules/${id}`);
        const professionalInfos = await getProfessionalInfos(
          authenticatedUser.professional.id,
        );

        if (
          data.status !== "AGENDADO" &&
          professionalInfos?.certificateId &&
          !data?.reportSigned &&
          isDesktop
        ) {
          setSignatureModal(true);
        } else {
          await finishAndRollback();
        }
      } else {
        setAlertComponent({
          message: "Fechando sala de atendimento...",
          severity: "success",
        });
        await finishAndRollback();
      }
    } catch (error) {
      setAlertComponent({
        message:
          error.response?.data?.message || "Erro ao finalizar atendimento",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [appointmentDetails]);

  const getSchedule = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/schedules/${id}`);

      if (data.status !== "AGENDADO") {
        // eslint-disable-next-line no-alert
        alert("Atendimento não agendado ou finalizado.");
        window.location.replace("/");
        return;
      }

      if (data.professional.id === authenticatedUser?.professional?.id) {
        const tokenValidated = localStorage.getItem("token");
        if (!tokenValidated || tokenValidated === "") {
          handleLogout();
          window.location.replace("/login");
        }

        const decodedToken = jose.decodeJwt(localStorage.getItem("token"));
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (decodedToken.exp <= currentTimestamp) {
          handleLogout();
          window.location.replace("/login");
        }

        setLocalUserName(authenticatedUser.professional.name);

        setTimeRoomLeft({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }

      setAppointmentDetails(data);
    } catch (err) {
      setAlertComponent({
        message: "Erro na busca do agendamento",
        severity: "error",
      });
      setNotFoundModal(true);
    }
    setLoading(false);
  };

  const notifyPatient = useCallback(async () => {
    if (!appointmentDetails) {
      setAlertComponent({
        message: "Agendamento não encontrado",
        severity: "error",
      });
      return;
    }
    try {
      const dataRequest = {
        title: "Lembrete de atendimento",
        message: `\nOlá ${appointmentDetails.user.name},\nO profissional ${appointmentDetails.professional.name} está aguardando você na sala de atendimento.\nAcesse o link abaixo:\n\nhttps://meupsiquiatraonline.com.br/meeting-room/${appointmentDetails.id}\n\nSe precisar de ajuda, entre em contato com o suporte.`,
        userIds: [appointmentDetails.user.id],
        method: ["EMAIL", "WHATSAPP", "PUSH"],
      };
      const response = await api.post("/notifications", dataRequest);
      if (response.status >= 400) {
        setAlertComponent({
          message: "Erro ao notificar o paciente",
          severity: "error",
        });
        return;
      }
      setAlertComponent({
        message: "Paciente notificado com sucesso!",
        severity: "success",
      });
    } catch (error) {
      setAlertComponent({
        message: "Erro ao notificar o paciente",
        severity: "error",
      });
    }
  }, [id, appointmentDetails]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (appointmentDetails && !meetingAuthorized && !meetingFinished) {
      intervalId.current = setInterval(() => {
        const time = calculateTimeLeft();
        setTimeLeft(time);

        if (
          time.days === 0 &&
          time.hours === 0 &&
          time.minutes === 0 &&
          time.seconds === 0
        ) {
          if (appointmentDetails) {
            setMeetingAuthorized(true);
          }
          clearInterval(intervalId.current);
        }
      }, 1000);

      return () => {
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }
        return undefined;
      };
    }
  }, [appointmentDetails, meetingAuthorized, meetingFinished]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      appointmentDetails &&
      meetingAuthorized &&
      authenticatedUser?.type === "professional" &&
      authenticatedUser?.professional?.id ===
        appointmentDetails?.professional?.id
    ) {
      roomIntervalId.current = setInterval(() => {
        const time = calculateTimeRoomLeft();
        setTimeRoomLeft(time);

        if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
          clearInterval(roomIntervalId.current);
        }
      }, 1000);

      return () => {
        if (roomIntervalId.current) {
          clearInterval(roomIntervalId.current);
        }
      };
    }
  }, [appointmentDetails, meetingAuthorized, authenticatedUser, timeRoomLeft]);

  useEffect(() => {
    if (localUserName !== "") {
      sessionStorage.setItem("localUserName", JSON.stringify(localUserName));
    }
  }, [localUserName]);

  useEffect(() => {
    const sessionTermsAccepted = sessionStorage.getItem("termsAccepted");
    if (!termsAccepted && sessionTermsAccepted === "true") {
      setAcceptModal(false);
    }
    if (termsAccepted && sessionTermsAccepted === "false") {
      sessionStorage.setItem("termsAccepted", "true");
    }
  }, [termsAccepted]);

  useEffect(() => {
    if (localUserName === "" && !authenticatedUser?.id) {
      setGetUserNameModal(true);
    }
  }, [localUserName]);

  useEffect(() => {
    if (appointmentDetails) {
      const meetDateTime = dayjs(appointmentDetails.date_start).add(3, "hours");
      const meetLimit = dayjs(appointmentDetails.date_end).add(3, "hours");

      const timeNow = dayjs().tz("America/Sao_Paulo");

      if (
        timeNow.isAfter(meetDateTime) &&
        timeNow.isBefore(meetLimit) &&
        appointmentDetails.status === "AGENDADO" &&
        !meetingFinished
      ) {
        if (
          authenticatedUser?.type === "professional" &&
          authenticatedUser?.professional?.id !==
            appointmentDetails?.professional?.id
        ) {
          setMeetingAuthorized(false);
          setAlertComponent({
            message:
              "Você não tem permissão para acessar essa sala de atendimento",
            severity: "error",
          });
        } else {
          setMeetingAuthorized(true);
          if (
            !sessionStorage.getItem("termsAccepted") ||
            sessionStorage.getItem("termsAccepted") === "false"
          ) {
            setAcceptModal(true);
          }
        }
      } else {
        setMeetingAuthorized(false);
        if (appointmentDetails.status !== "AGENDADO") {
          setMeetingFinished(true);
        }
      }
    }
  }, [appointmentDetails]);

  useEffect(() => {
    async function getInfos() {
      if (!appointmentDetails) {
        await getSchedule();
      }
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({ message: "", severity: "" });
      }, 10000);
    }
  }, [alertComponent]);
  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Processando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <MKSnackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        icon="notifications"
        title="Notificação"
        content={alertComponent.message}
        open={alertComponent.message !== ""}
        close={() => setAlertComponent({ message: "", severity: "" })}
        color={alertComponent.severity}
        dateTime=""
      />

      {meetingAuthorized &&
        appointmentDetails &&
        appointmentDetails?.roomUrl &&
        !meetingFinished &&
        localUserName !== "" && (
          <MeetingRoom
            roomUrl={appointmentDetails.roomUrl}
            localUserName={localUserName}
            finalizeAppointment={finalizeAppointment}
            notifyPatient={notifyPatient}
            isProfessional={
              authenticatedUser?.professional?.id ===
              appointmentDetails.professional.id
            }
          />
        )}
      {meetingAuthorized &&
        appointmentDetails &&
        (!appointmentDetails?.roomUrl ||
          appointmentDetails?.roomUrl === "") && (
          <Box
            component="section"
            py={{ xs: 2, md: 5 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100vh",
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
            }}
          >
            <Box>
              <Typography variant="h5" px={3} textAlign="center">
                Sala de atendimento indisponível
              </Typography>
              <Typography variant="body2" px={3} textAlign="center">
                Entre em contato com o suporte
              </Typography>
            </Box>
          </Box>
        )}
      {!meetingAuthorized && appointmentDetails && !meetingFinished && (
        <Box
          component="section"
          py={{ xs: 2, md: 5 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            borderRadius: "10px",
            backgroundColor: "#f0f0f0",
          }}
        >
          <Box>
            <Typography variant="h5" px={3} textAlign="center">
              O atendimento começará em:
            </Typography>
            <Typography variant="h4" px={3} textAlign="center" py={2}>
              <Grid container display="flex" justifyContent="space-around">
                <Box>
                  <Typography variant="h3">
                    {String(timeLeft.days).padStart(2, "0")}
                  </Typography>
                  <Typography variant="body2">DIAS</Typography>
                </Box>
                <Box>
                  <Typography variant="h3">
                    {String(timeLeft.hours).padStart(2, "0")}
                  </Typography>
                  <Typography variant="body2">HORAS</Typography>
                </Box>
                <Box>
                  <Typography variant="h3">
                    {String(timeLeft.minutes).padStart(2, "0")}
                  </Typography>
                  <Typography variant="body2">MINUTOS</Typography>
                </Box>
                <Box>
                  <Typography variant="h3">
                    {String(timeLeft.seconds).padStart(2, "0")}
                  </Typography>
                  <Typography variant="body2">SEGUNDOS</Typography>
                </Box>
              </Grid>
            </Typography>
            <Typography variant="body2" px={3} textAlign="center">
              Aguarde o horário de seu atendimento para entrar na sala!
            </Typography>
          </Box>
        </Box>
      )}
      {appointmentDetails && meetingFinished && (
        <Box
          component="section"
          py={{ xs: 2, md: 5 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
          }}
        >
          <Box>
            <Typography variant="h6" px={3} textAlign="center">
              Seu atendimento foi finalizado
            </Typography>
            {authenticatedUser?.type === "professional" &&
              appointmentDetails?.status === "AGENDADO" && (
                <Button
                  onClick={() => finalizeAppointment()}
                  variant="contained"
                  sx={{ width: "100%", marginTop: 2 }}
                >
                  Encerrar Atendimento
                </Button>
              )}
          </Box>
        </Box>
      )}
      {authenticatedUser?.type === "professional" &&
        authenticatedUser?.professional?.id ===
          appointmentDetails?.professional?.id &&
        appointmentDetails && (
          <>
            {isDesktop && (
              <>
                <MedicalTimeLine
                  patientId={appointmentDetails.user?.id}
                  userName={appointmentDetails?.user?.name || ""}
                />
                <EletronicMedicalRecord appointmentInfos={appointmentDetails} />
              </>
            )}
            {timeRoomLeft?.seconds && (
              <Box
                style={{
                  position: "fixed",
                  top: "5px",
                  right: `${Math.round(windowWidth * 0.5) - 100}px`,
                  width: "200px",
                  height: "60px",
                  borderRadius: "10px",
                  backgroundColor: "#FF785B70",
                  zIndex: 300000,
                }}
              >
                <Typography variant="body2" textAlign="center" color="white">
                  TEMPO RESTANTE
                </Typography>
                <Typography variant="h4" textAlign="center" color="white">
                  {String(timeRoomLeft.hours).padStart(2, "0")}:
                  {String(timeRoomLeft.minutes).padStart(2, "0")}:
                  {String(timeRoomLeft.seconds).padStart(2, "0")}
                </Typography>
                {appointmentDetails?.plan?.isEnterprise && (
                  <Typography variant="body2" textAlign="center" color="white">
                    PLANO EMPRESARIAL
                  </Typography>
                )}
              </Box>
            )}
            {isDesktop && (
              <Box
                style={{
                  position: "fixed",
                  bottom: "5px",
                  right: `${Math.round(windowWidth * 0.5) - 100}px`,
                  width: "200px",
                  height: "30px",
                  borderRadius: "10px",
                  backgroundColor: "#FF785B70",
                  zIndex: 2000,
                }}
              >
                <Button
                  onClick={() => setDocumentGeneratorModal(true)}
                  style={{ color: "white", width: "100%", height: "100%" }}
                >
                  GERAR DOCUMENTO
                </Button>
              </Box>
            )}
          </>
        )}

      <Dialog
        open={acceptModal}
        onClose={() => acceptTermsModal()}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography variant="body1">AVISO</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "#FFE8E8",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              Você precisa CONCEDER PERMISSÃO de acesso a sua CÂMERA e MICROFONE
              para utilizar a sala de atendimento.
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              Sem essa permissão não é possível utilizar a sala de atendimento e
              essa mensagem não será removida da sua tela.
            </Typography>
          </Box>
          {authenticatedUser?.type === "professional" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                backgroundColor: "red",
                borderRadius: "10px",
                padding: 2,
                marginY: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: "700", color: "yellow" }}
              >
                PROFISSIONAL...
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    margin: 2,
                    padding: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                  }}
                >
                  <Box
                    component="img"
                    src={phoneoff}
                    alt="phoneoff"
                    style={{ width: "40px" }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    color: "#ffffff",
                  }}
                >
                  Utilize o botão de ENCERRAR A CHAMADA para finalizar o
                  atendimento ou você terá que finalizá-lo no painel de
                  agendamentos.
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ color: "#ffffff" }}>
                O atendimento precisa ser finalizado para liberar a sala para o
                próximo paciente.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingX: "40px",
            paddingY: "10px",
          }}
        >
          <Button
            onClick={() => acceptTermsModal()}
            style={{
              background: "linear-gradient(to bottom right, #11284F, #11B3AE)",
              color: "white",
              textAlign: "center",
            }}
          >
            CLIQUE AQUI PARA CONFIRMAR E ACESSAR A SALA DE ATENDIMENTO
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={notFoundModal}
        maxWidth="sm"
        onClose={closeNotFoundModal}
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography>AGENDAMENTO NÃO ENCONTRADO</Typography>
          <CloseIcon onClick={closeNotFoundModal} sx={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              backgroundColor: "#FFE8E8",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "700" }}
            >
              Por favor, entre em contato com o suporte.
            </Typography>
            <Typography
              variant="body2"
              sx={{ textAlign: "center", fontWeight: "500" }}
            >
              Não foi possível iniciar o atendimento.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Modal para assinatura de prontuário */}
      <Dialog
        open={signatureModal}
        onClose={closeSignatureModal}
        maxWidth="sm"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Assinatura Digital de Prontuário</h3>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {appointmentDetails && (
            <DocumentSignature
              appointmentInfo={appointmentDetails}
              setSignatureModal={setSignatureModal}
              closeSignatureModal={closeSignatureModal}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Modal para gerar documentos */}
      <Dialog
        open={documentGeneratorModal}
        onClose={() => setDocumentGeneratorModal(false)}
        maxWidth="lg"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography>Gerador de Documentos</Typography>
          <CloseIcon
            onClick={() => setDocumentGeneratorModal(false)}
            sx={{ cursor: "pointer" }}
          />
        </DialogTitle>
        <DialogContent dividers>
          {appointmentDetails && (
            <DocumentGenerator
              appointmentInfo={appointmentDetails}
              closeModal={() => setDocumentGeneratorModal(false)}
            />
          )}
        </DialogContent>
      </Dialog>

      {/* Modal para pegar nome do usuário não autenticado */}
      <Dialog
        open={getUserNameModal}
        maxWidth="sm"
        onClose={handleGetUserNameModal}
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <Typography>USUÁRIO NÃO AUTENTICADO</Typography>
          <CloseIcon onClick={closeNotFoundModal} sx={{ cursor: "pointer" }} />
        </DialogTitle>
        <DialogContent dividers sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              padding: "20px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "700" }}
            >
              Informe seu nome para entrar na sala de atendimento.
            </Typography>
            <TextField
              label="Nome"
              variant="outlined"
              value={userNameInput}
              onChange={(e) => setUserNameInput(e.target.value)}
              sx={{ width: "100%", marginY: "10px" }}
              required
            />
            <Button
              onClick={handleGetUserNameModal}
              style={{
                background:
                  "linear-gradient(to bottom right, #11284F, #11B3AE)",
                color: "white",
                textAlign: "center",
                marginTop: "10px",
                width: "100%",
              }}
            >
              ENTRAR NA SALA DE ATENDIMENTO
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MeetingRoomPage;
