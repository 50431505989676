/* eslint-disable react/prop-types */
import * as React from "react";

function getIconProps(variant) {
  switch (variant) {
    case "camera":
      return {
        alt: "camera",
        activeBgColor: "#2F66F71A",
        bgColor: "#F26B4D",
        iconSource: "/camera.svg",
        offIcon: "/cam-off.svg",
      };
    case "microphone":
      return {
        alt: "microphone",
        activeBgColor: "#03AB191A",
        bgColor: "#F26B4D",
        iconSource: "/microphone.svg",
        offIcon: "/mic-off.svg",
      };
    case "fullscreen":
      return {
        alt: "fullscreen",
        activeBgColor: "#03AB191A",
        bgColor: "#F26B4D",
        iconSource: "/normal-screen.svg",
        offIcon: "/full-screen.svg",
      };
    case "close":
      return {
        alt: "close",
        activeBgColor: "#03AB191A",
        bgColor: "#F26B4D",
        iconSource: "/phoneoff.svg",
        offIcon: "/phoneoff.svg",
      };
    default:
      return {
        alt: "camera",
        activeBgColor: "#F26B4D",
        bgColor: "#2F66F71A",
        iconSource: "/camera.svg",
        offIcon: "/cam-off.svg",
      };
  }
}

export default function IconButton({
  variant = "camera",
  isActive = true,
  ...rest
}) {
  const { alt, activeBgColor, bgColor, iconSource, offIcon } =
    getIconProps(variant);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        fontSize: "14px",
      }}
    >
      <button
        type="button" // Add the type attribute with the value of "button"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "16px",
          padding: 5,
          border: "none",
          backgroundColor: isActive ? activeBgColor : bgColor,
        }}
        {...rest}
      >
        {isActive ? (
          <img src={iconSource} alt={alt} />
        ) : (
          <img src={offIcon} alt={alt} />
        )}
      </button>
    </div>
  );
}
