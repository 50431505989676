import React, { useEffect, useState } from "react";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";

import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

// images
import LogoMPO from "assets/images/logo_horizontal.png";
import MKButton from "components/MKButton";

import { api } from "../../../lib/axios";
import MedicalTimeLine from "../../../components/MedicalTimeLine";

function UserAdminPanel() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(localizedFormat);
  dayjs.locale("pt-br");

  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "",
  });
  const [alertComponentModal, setAlertComponentModal] = useState({
    message: "",
    type: "",
  });

  const [loading, setLoading] = useState(false);
  const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);
  const [openNewUserModal, setOpenNewUserModal] = useState(false);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);

  const [userDetails, setUserDetails] = useState({});

  const [users, setUsers] = useState([]);

  const [name, setName] = useState(" ");
  const [cpf, setCpf] = useState(" ");
  const [email, setEmail] = useState(" ");
  const [password, setPassword] = useState(" ");
  const [phone, setPhone] = useState(" ");
  const [type, setType] = useState("user");
  const [userActive, setUserActive] = useState(true);

  const [timelineUser, setTimelineUser] = useState(null);

  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";

  const toggleSwitchUserActive = () => {
    setUserActive(!userActive);
  };

  const handleCPF = (cpfInput) => {
    if (!cpfInput || cpfInput?.length === 0) {
      return;
    }
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const handleCellPhone = (cellPhoneInput) => {
    if (!cellPhoneInput || cellPhoneInput?.length === 0) {
      return;
    }
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setPhone(cellPhoneFormated);
  };

  const convertCellPhone = (cellPhoneInput) => {
    if (!cellPhoneInput || cellPhoneInput?.length === 0) {
      return "";
    }
    let cellphoneNumbers = cellPhoneInput.replace(/-/g, "");
    if (cellphoneNumbers.length === 13) {
      cellphoneNumbers = cellphoneNumbers.substring(2);
    }
    return cellphoneNumbers
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const convertCPF = (cpfInput) => {
    if (!cpfInput || cpfInput?.length === 0) {
      return "";
    }
    return cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
  };

  const verifyUserType = (typeReceived) => {
    if (typeReceived === 1) {
      return "Administrador";
    }
    if (typeReceived === 2) {
      return "Usuário";
    }
    if (typeReceived === 3) {
      return "Profissional";
    }
    return "Gerente";
  };

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    return remainder === parseInt(numbersCPF[10], 10);
  };

  const handleOpenUserDetails = (row) => {
    setUserDetails(row);
    setOpenUserDetailsModal(true);
  };

  const handleUpdateUserModal = (row) => {
    setUserDetails(row);
    setName(row.name);
    setCpf(convertCPF(row.cpf));
    setEmail(row.email);
    setPhone(convertCellPhone(row.cellphone));
    setUserActive(row.active);
    setOpenUpdateUserModal(true);
  };

  const clearUser = () => {
    setName(" ");
    setCpf(" ");
    setEmail(" ");
    setPassword(" ");
    setPhone(" ");
    setType("2");
    setUserActive(true);
    setUserDetails({});
  };

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await api.get("/users");
      const usersOrdered = response.data.sort((a, b) =>
        (a.name || "").localeCompare(b.name || ""),
      );
      setUsers(usersOrdered);
    } catch (error) {
      setAlertComponent({
        message: "Erro na extração dos usuários",
        type: "error",
      });
    }
    setLoading(false);
  };

  const handleActivateUser = async (userInfo) => {
    try {
      let newActivate = true;
      if (userInfo.active === true) {
        newActivate = false;
      }
      const response = await api.put(`/users/${userInfo.id}`, {
        active: newActivate,
      });

      setOpenUserDetailsModal(false);
      if (response.status === 200) {
        await getUsers();
        setAlertComponent({
          message: "Usuário alterado com sucesso",
          type: "success",
        });
      } else {
        setAlertComponent({
          message: "Erro ao alterar usuário",
          type: "error",
        });
      }
    } catch (error) {
      setOpenUserDetailsModal(false);
      setAlertComponent({
        message: "Erro na execução da alteração do usuário",
        type: "error",
      });
    }
  };

  const createNewUser = async () => {
    if (name.trim() === "") {
      setAlertComponentModal({
        message: "nome inválido",
        type: "error",
      });
      return;
    }

    if (email.trim() === "" || email.trim().indexOf("@") === -1) {
      setAlertComponentModal({
        message: "E-mail inválido",
        type: "error",
      });
      return;
    }

    if (phone.replace(/\D/g, "").length !== 11) {
      setAlertComponentModal({
        message: "Celular inválido",
        type: "error",
      });
      return;
    }

    const userCPF = cpf.replace(/\D/g, "");
    if (!isValidCPF(userCPF)) {
      setAlertComponentModal({
        message: "CPF inválido",
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const userCell = `55${phone.replace(/\D/g, "")}`;
      const response = await api.post("users", {
        name,
        cellphone: userCell,
        email,
        password,
        cpf: userCPF,
      });

      if (response.status === 201 && response.data) {
        setOpenNewUserModal(false);
        clearUser();
        await getUsers();
        setAlertComponent({
          message: "Usuário criado com sucesso.",
          type: "success",
        });
      } else {
        setOpenNewUserModal(false);
        clearUser();
        setAlertComponent({
          message: "Desculpe! Um erro não previsto aconteceu.",
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponentModal({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const updateUser = async () => {
    if (name.trim() === "") {
      setAlertComponentModal({
        message: "nome inválido",
        type: "error",
      });
      return;
    }

    if (email.trim() === "" || email.trim().indexOf("@") === -1) {
      setAlertComponentModal({
        message: "E-mail inválido",
        type: "error",
      });
      return;
    }

    if (phone.replace(/\D/g, "").length !== 11) {
      setAlertComponentModal({
        message: "Celular inválido",
        type: "error",
      });
      return;
    }

    const userCPF = cpf.replace(/\D/g, "");
    if (!isValidCPF(userCPF)) {
      setAlertComponentModal({
        message: "CPF inválido",
        type: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const userCell = `55${phone.replace(/\D/g, "")}`;

      const bodyRequest = {
        name,
        cellphone: userCell,
        email,
        cpf: userCPF,
        active: userActive,
      };

      const response = await api.put(`users/${userDetails.id}`, bodyRequest);

      if (response.status === 200 && response.data) {
        setOpenUpdateUserModal(false);
        clearUser();
        await getUsers();
        setAlertComponent({
          message: "Usuário atualizado com sucesso.",
          type: "success",
        });
      } else {
        setOpenUpdateUserModal(false);
        clearUser();
        setAlertComponent({
          message: "Desculpe! Um erro não previsto aconteceu.",
          type: "error",
        });
      }
    } catch (err) {
      setAlertComponentModal({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const extractType = (params) => {
    if (params.row.type === 1) {
      return "Administrador";
    }
    if (params.row.type === 2) {
      return "Usuário";
    }
    if (params.row.type === 3) {
      return "Profissional";
    }
    return "Gerente";
  };

  const extractCPF = (params) => {
    const cpfFormatedResponse = convertCPF(params.row.cpf);
    return <span>{cpfFormatedResponse}</span>;
  };

  const extractActivate = (params) => {
    if (params.row.active === true) {
      return <span>Sim</span>;
    }
    return <span>Não</span>;
  };

  const extractCellPhone = (params) => {
    let numCell = "";
    if (params.row?.cellphone) {
      numCell = convertCellPhone(params.row.cellphone.substring(2));
    }
    return <span>{numCell}</span>;
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
      headerClassName: "data-grid-header",
      hide: true,
    },
    {
      field: "name",
      headerName: "Nome",
      flex: 1.5,
      headerClassName: "data-grid-header",
      renderCell: ({ row }) => row.name ?? "Sem nome",
    },
    {
      field: "cpf",
      headerName: "CPF",
      flex: 0.5,
      headerClassName: "data-grid-header",
      hide: true,
      renderCell: extractCPF,
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      headerClassName: "data-grid-header",
    },
    {
      field: "cellphone",
      headerName: "Telefone",
      flex: 0.5,
      headerClassName: "data-grid-header",
      renderCell: extractCellPhone,
    },
    {
      field: "type",
      headerName: "Tipo",
      flex: 0.2,
      headerClassName: "data-grid-header",
      hide: true,
      renderCell: extractType,
    },
    {
      field: "active",
      headerName: "Ativo",
      flex: 0.2,
      headerClassName: "data-grid-header",
      hide: true,
      renderCell: extractActivate,
    },
    {
      field: "actions",
      headerName: "Detalhes",
      flex: 0.8,
      headerClassName: "dataGrid-header",
      renderCell: ({ row }) => (
        <MKBox>
          <IconButton size="large" onClick={() => handleOpenUserDetails(row)}>
            <PreviewIcon />
          </IconButton>
          <IconButton
            size="large"
            onClick={() => {
              setTimelineUser(null);
              setTimelineUser(row);
            }}
          >
            <ListAltIcon />
          </IconButton>
          <IconButton size="large" onClick={() => handleUpdateUserModal(row)}>
            <EditIcon />
          </IconButton>
        </MKBox>
      ),
    },
  ];

  useEffect(() => {
    if (!authenticatedUser) {
      window.location.replace("/login");
    }
    async function getInfos() {
      await getUsers();
    }
    getInfos();
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  useEffect(() => {
    if (alertComponentModal.message !== "") {
      setTimeout(() => {
        setAlertComponentModal({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponentModal]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <MKBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <MKBox sx={{ maxHeight: "100px", overflow: "auto" }}>
            <MKTypography
              variant="body1"
              style={{ color: "white", padding: 20 }}
            >
              Carregando...
            </MKTypography>
          </MKBox>
        </MKBox>
      </Backdrop>

      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />

      <Grid
        item
        xs={12}
        lg={12}
        pt={20}
        px={2}
        style={{ height: "900px", width: "100%" }}
      >
        {alertComponent.message !== "" && (
          <Grid item xs={12} p={2}>
            <MKAlert color={alertComponent.type}>
              {alertComponent.message}
            </MKAlert>
          </Grid>
        )}
        {Object.keys(users).length > 0 && (
          <DataGrid
            rows={users}
            columns={columns}
            rowsPerPageOptions={[10, 25, 50, 100]}
            disableSelectionOnClick
            slots={{ toolbar: GridToolbar }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Grid>

      <Fab
        sx={{ position: "fixed", bottom: 30, left: 30 }}
        color="success"
        aria-label="add"
        onClick={() => setOpenNewUserModal(true)}
      >
        <AddIcon color="white" />
      </Fab>

      <Dialog
        open={openNewUserModal}
        onClose={() => setOpenNewUserModal(false)}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Cadastro de Usuário</h3>
          </div>
          <div>
            <IconButton onClick={() => setOpenNewUserModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponentModal.message !== "" && (
            <Grid item xs={12} p={2}>
              <MKAlert color={alertComponentModal.type}>
                {alertComponentModal.message}
              </MKAlert>
            </Grid>
          )}
          <MKBox mx={2} mb={1} pt={1}>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="Nome"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="CPF"
                name="cpf"
                value={cpf}
                placeholder="999.999.999-99"
                onChange={(e) => handleCPF(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="E-mail"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="Celular"
                type="phone"
                name="phone"
                value={phone}
                placeholder="(99) 99999-9999"
                onChange={(e) => handleCellPhone(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="Senha"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <FormControl fullWidth>
                <InputLabel id="type">Tipo de Cadastro</InputLabel>
                <Select
                  variant="outlined"
                  labelId="type"
                  id="type"
                  value={type}
                  sx={{ height: "45px" }}
                  onChange={(e) => setType(e.target.value)}
                  required
                >
                  <MenuItem value="2">Usuário</MenuItem>
                  <MenuItem value="3">Profissional</MenuItem>
                  <MenuItem value="1">Administrador</MenuItem>
                </Select>
              </FormControl>
            </MKBox>
            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={clearUser}
                  fullWidth
                >
                  Limpar Dados
                </MKButton>
              </MKBox>
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={createNewUser}
                  fullWidth
                >
                  Gravar Dados
                </MKButton>
              </MKBox>
            </Grid>
          </MKBox>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUpdateUserModal}
        onClose={() => setOpenUpdateUserModal(false)}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Atualizar Usuário</h3>
          </div>
          <div>
            <IconButton onClick={() => setOpenUpdateUserModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponentModal.message !== "" && (
            <Grid item xs={12} p={2}>
              <MKAlert color={alertComponentModal.type}>
                {alertComponentModal.message}
              </MKAlert>
            </Grid>
          )}
          <MKBox mx={2} mb={1} pt={1}>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="Nome"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="CPF"
                name="cpf"
                value={cpf}
                placeholder="999.999.999-99"
                onChange={(e) => handleCPF(e.target.value)}
                fullWidth
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="E-mail"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <MKBox width="100%" py={1}>
              <MKInput
                variant="outlined"
                label="Celular"
                type="phone"
                name="phone"
                value={phone}
                placeholder="(99) 99999-9999"
                onChange={(e) => handleCellPhone(e.target.value)}
                fullWidth
                required
              />
            </MKBox>
            <Grid item xs={12} md={12}>
              <MKBox
                width="100%"
                py={1}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <Switch
                  checked={userActive}
                  onChange={toggleSwitchUserActive}
                />
                <MKBox lineHeight={0.5}>
                  <MKTypography
                    display="block"
                    variant="button"
                    fontWeight="bold"
                  >
                    Usuário {userActive ? "Ativo" : "Inativo"}
                  </MKTypography>
                </MKBox>
              </MKBox>
            </Grid>
            <Grid item sx={{ display: "flex", flexDirection: "row" }}>
              <MKBox width="100%" p={2}>
                <MKButton
                  variant="gradient"
                  color="info"
                  onClick={updateUser}
                  fullWidth
                >
                  Atualizar Dados do Usuário
                </MKButton>
              </MKBox>
            </Grid>
          </MKBox>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openUserDetailsModal}
        onClose={() => setOpenUserDetailsModal(false)}
        scroll="body"
        aria-labelledby="times-avaliable-title"
        aria-describedby="times-avaliable-description"
        fullWidth
      >
        <DialogTitle
          id="times-avaliable-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <h3>Detalhes do Usuário</h3>
          </div>
          <div>
            <IconButton onClick={() => setOpenUserDetailsModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {alertComponentModal.message !== "" && (
            <Grid item xs={12} p={2}>
              <MKAlert color={alertComponentModal.type}>
                {alertComponentModal.message}
              </MKAlert>
            </Grid>
          )}
          <Grid container p={2}>
            {Object.keys(userDetails).length > 0 && (
              <Grid item xs={12} md={12}>
                <Typography>{`ID: ${userDetails.id}`}</Typography>
                <Typography>{`Usuário: ${userDetails.name}`}</Typography>
                <Typography>{`E-mail: ${userDetails.email}`}</Typography>
                <Typography>{`Telefone: ${convertCellPhone(
                  userDetails.cellphone.substring(2),
                )}`}</Typography>
                <Typography>{`CPF: ${convertCPF(userDetails.cpf)}`}</Typography>
                <Typography>{`Tipo: ${verifyUserType(
                  userDetails.type,
                )}`}</Typography>
                <Typography>{`Ativado: ${
                  userDetails.active ? "Sim" : "Não"
                }`}</Typography>
                <Typography>{`Criado em: ${dayjs(userDetails.created_at)
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY HH:mm:ss")}`}</Typography>
                <Typography>{`Excluído em: ${
                  userDetails?.deleted_at
                    ? dayjs
                        .tz(userDetails.deleted_at, "America/Sao_Paulo")
                        .format("DD/MM/YYYY HH:mm:ss")
                    : ""
                }`}</Typography>
                <Divider />
                <Typography>{`CEP: ${userDetails.zip ?? ""}`}</Typography>
                <Typography>{`Logradouro: ${
                  userDetails.address ?? ""
                }`}</Typography>
                <Typography>{`Número: ${userDetails.number ?? ""}`}</Typography>
                <Typography>{`Bairro: ${
                  userDetails.neighborhood ?? ""
                }`}</Typography>
                <Typography>{`Complemento: ${
                  userDetails.complement ?? ""
                }`}</Typography>
                <Typography>{`Cidade: ${userDetails.city ?? ""}`}</Typography>
                <Typography>{`Estado: ${userDetails.state ?? ""}`}</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleActivateUser(userDetails)}>
            {userDetails.active ? "Desativar Usuário" : "Ativar Usuário"}
          </Button>
          {/* <Button onClick={() => handleUserProfessional(userDetails)}>
            {userDetails.type === 3 ? "Tornar Usuário" : "Tornar Profissional"}
          </Button> */}
        </DialogActions>
      </Dialog>

      {timelineUser && (
        <MedicalTimeLine
          patientId={timelineUser.id}
          userName={timelineUser.name}
        />
      )}
    </>
  );
}

export default UserAdminPanel;
