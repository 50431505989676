import React, { useCallback, useEffect, useState } from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";

import ReactQuill from "react-quill";
import "quill/dist/quill.snow.css";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloudDownloadTwoToneIcon from "@mui/icons-material/CloudDownloadTwoTone";
import TextSnippetTwoToneIcon from "@mui/icons-material/TextSnippetTwoTone";
import InfoIcon from "@mui/icons-material/Info";

import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");
dayjs.tz.setDefault("America/Sao_Paulo");

function MedicalTimeLine(params) {
  const { patientId, userName } = params;
  const windowHeight = window?.innerHeight || null;

  const [loading, setLoading] = useState(false);
  const [alertInfos, setAlertInfos] = useState({
    type: "",
    message: "",
  });

  const [timelineInfos, setTimelineInfos] = useState(null);
  const [medicalRecord, setMedicalRecord] = useState(null);

  const [toggleWindow, setToggleWindow] = useState(false);
  const [medicalRecordModal, setMedicalRecordModal] = useState(false);
  const [showItemTimeLine, setShowIntemTimeLine] = useState("");

  const TypeDocumentParse = {
    prescription: "RECEITA",
    report: "RELATÓRIO",
    certificate: "ATESTADO ",
    referral: "ENCAMINHAMENTO",
    exam: "SOLICITAÇÃO EXAME",
  };

  const handleShowInfos = useCallback((timelineId) => {
    if (showItemTimeLine === timelineId) {
      setShowIntemTimeLine("");
      return;
    }
    setShowIntemTimeLine(timelineId);
  }, []);

  const handleDrawer = useCallback(() => {
    setToggleWindow(!toggleWindow);
  }, []);

  const handleCloseMedicalRecordModal = useCallback(() => {
    setMedicalRecordModal(false);
    setMedicalRecord(null);
  }, []);

  const getTimeLine = async (id) => {
    setLoading(true);
    try {
      const response = await api.get(`/timeline/${id}`);
      if (response.status === 401) {
        setAlertInfos({
          message: "Acesso não autorizado na busca do histórico do paciente",
          type: "error",
        });
        return;
      }
      if (response.status >= 400) {
        setAlertInfos({
          message: "Erro ao buscar o histórico do paciente",
          type: "error",
        });
        return;
      }
      const data = await response.data;
      const timelineFiltered = data.filter(
        (item) =>
          item.appointment?.hasReport ||
          (item.appointment?.attachments &&
            item.appointment.attachments.length > 0),
      );
      const timelineFilteredBefore2HoursAgo = timelineFiltered.filter((item) =>
        dayjs().isAfter(dayjs(item.date).add(2, "hours")),
      );
      setTimelineInfos(timelineFilteredBefore2HoursAgo);
      setToggleWindow(true);
    } catch (err) {
      setAlertInfos({
        message: "Erro ao buscar o histórico do paciente",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const openMedicalRecord = useCallback(
    (itemSelected) => {
      if (!itemSelected) {
        setAlertInfos({
          message: "Referência do prontuário não encontrada",
          type: "error",
        });
        return;
      }
      setMedicalRecord({
        date: itemSelected.date,
        professional: itemSelected.professional.name,
        report: itemSelected.appointment.report,
      });
      setMedicalRecordModal(true);
      setToggleWindow(false);
    },
    [setAlertInfos],
  );

  const handleDownloadFile = useCallback(
    (fileId) => {
      if (!fileId) {
        setAlertInfos({
          message: "Referência do arquivo não encontrada",
          type: "error",
        });
        return;
      }
      try {
        const host = window.location.origin;
        window.open(`${host}/download/${fileId}`, "_blank");
      } catch (error) {
        setAlertInfos({
          message: "Erro ao baixar arquivo",
          type: "error",
        });
      }
    },
    [setAlertInfos],
  );

  const renderTimeLineItemInfos = useCallback(
    (infos) => (
      <Box
        sx={{
          backgroundColor: "#F3F3F3",
          borderRadius: "10px",
          marginY: "5px",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {infos.type === "appointment" && infos.appointment.hasReport && (
            <Box
              onClick={() => openMedicalRecord(infos)}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                cursor: "pointer",
                margin: "5px",
              }}
            >
              <TextSnippetTwoToneIcon />
              <Typography variant="caption" sx={{ marginX: 1 }}>
                Anotações
              </Typography>
            </Box>
          )}

          {infos?.appointment?.attachments &&
            infos.appointment.attachments.length > 0 &&
            infos.appointment.attachments.map((item) => (
              <Box
                key={item.uuid}
                onClick={() => handleDownloadFile(item.uuid)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  cursor: "pointer",
                  marginX: "5px",
                }}
              >
                <CloudDownloadTwoToneIcon />
                <Typography variant="caption" sx={{ marginX: 1 }}>
                  {item?.description || "Arquivo sem descrição"}
                  {item?.description !== "" &&
                  Object.keys(TypeDocumentParse).some((key) =>
                    item.description.startsWith(key),
                  )
                    ? TypeDocumentParse[item.description.split("_")[0]]
                    : item.description}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    ),
    [handleDownloadFile, openMedicalRecord, TypeDocumentParse],
  );

  const handleClickOutside = useCallback((event) => {
    const drawerElement = document.querySelector(".MuiDrawer-root");
    const toggleButton = document.querySelector(".drawer-toggle-button");
    if (
      drawerElement &&
      !drawerElement.contains(event.target) &&
      toggleButton &&
      !toggleButton.contains(event.target)
    ) {
      setToggleWindow(false);
    }
  }, []);

  useEffect(() => {
    if (toggleWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleWindow, handleClickOutside]);

  useEffect(() => {
    const getInfos = async () => {
      if (!timelineInfos) {
        await getTimeLine(patientId);
      }
    };
    getInfos();
  }, [patientId]);

  useEffect(() => {
    if (alertInfos.message !== "") {
      setTimeout(() => {
        setAlertInfos({
          message: "",
          type: "",
        });
      }, 5000);
    }
  }, [alertInfos]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          left: `${toggleWindow ? "350" : 0}px`,
          backgroundColor: "#FF785B70",
          width: "50px",
          bottom: `${Math.round(windowHeight / 2) - 125}px`,
          borderRadius: "0px 10px 10px 0px",
          zIndex: 20000,
          cursor: "pointer",
        }}
        onClick={handleDrawer}
      >
        <Box sx={{ position: "relative", height: "200px" }}>
          <Typography
            sx={{
              transform: "rotate(90deg)",
              position: "absolute",
              transformOrigin: "center left",
              width: "250px",
              top: 20,
              left: 20,
              fontWeight: "bold",
              fontSize: "14px",
              zIndex: 30000,
              marginLeft: "10px",
            }}
            variant="caption"
          >
            Timeline do Paciente
          </Typography>
          {loading && (
            <Typography
              sx={{
                transform: "rotate(90deg)",
                position: "absolute",
                transformOrigin: "center left",
                width: "250px",
                top: 20,
                left: 5,
                fontWeight: "bold",
                fontSize: "14px",
                zIndex: 30000,
                marginLeft: "10px",
              }}
              variant="caption"
            >
              carregando dados... aguarde!
            </Typography>
          )}
        </Box>
        <IconButton sx={{ marginLeft: "10px" }}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="left"
        open={toggleWindow}
        onClose={() => setToggleWindow(false)}
        className="drawer-toggle-button"
        sx={{ zIndex: 30000 }}
      >
        <Box
          style={{
            width: "350px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Typography variant="body2">{userName}</Typography>
          </Box>
          {alertInfos.message !== "" && (
            <Alert
              severity={alertInfos.type}
              sx={{ marginX: "20px", marginY: "10px" }}
            >
              {alertInfos.message}
            </Alert>
          )}
          {loading && (
            <Box sx={{ width: "100%", padding: 1, textAlign: "center" }}>
              <Typography variant="body1">
                Aguarde! Estamos carregando as informações.
              </Typography>
            </Box>
          )}
          <Timeline>
            {timelineInfos &&
              timelineInfos?.length > 0 &&
              timelineInfos.map((item) => (
                <TimelineItem
                  key={item.date}
                  sx={{
                    "&:before": {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  <TimelineSeparator>
                    <TimelineDot
                      onClick={() => handleShowInfos(item.date)}
                      sx={{ cursor: "pointer", backgroundColor: "#000000" }}
                    >
                      <InfoIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box>
                      <Typography fontSize={12} height={15} fontWeight="bold">
                        {dayjs(item.date)
                          .tz("America/Sao_Paulo")
                          .format("DD/MM/YYYY [às] HH:mm")}
                      </Typography>
                      <Typography fontSize={12} height={15}>
                        {item.professional.name}
                      </Typography>
                      <Typography fontSize={10} height={15}>
                        {item.professional.specialty}
                      </Typography>
                      {item?.patient?.company && (
                        <Typography fontSize={8} height={10}>
                          {item.patient.company}
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display:
                            showItemTimeLine === item.date ? "block" : "none",
                        }}
                      >
                        {renderTimeLineItemInfos(item)}
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </Box>
      </Drawer>

      <Dialog
        open={medicalRecordModal}
        onClose={handleCloseMedicalRecordModal}
        maxWidth="xl"
        fullWidth
        sx={{ zIndex: 20000 }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Prontuário do Paciente</Typography>
          <IconButton onClick={handleCloseMedicalRecordModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {medicalRecord && (
            <Box
              sx={{ display: "flex", flexDirection: "column", padding: "10px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "5px",
                  backgroundColor: "#f3f3f3",
                  margin: "2px",
                  padding: "5px",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  Data:
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {dayjs(medicalRecord.date)
                    .add(3, "hour")
                    .tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY [às] HH:mm")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "5px",
                  backgroundColor: "#f3f3f3",
                  margin: "2px",
                  padding: "5px",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  Profissional:
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {medicalRecord.professional}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  borderRadius: "5px",
                  backgroundColor: "#f3f3f3",
                  margin: "2px",
                  padding: "5px",
                  width: "100%",
                }}
              >
                <ReactQuill
                  readOnly
                  theme="snow"
                  modules={{ toolbar: false }}
                  value={medicalRecord.report}
                  style={{ width: "100%", height: "50vh" }}
                />
              </Box>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default MedicalTimeLine;
